<template>
  <div class="contact-container">
    <div class="row no-margin">
      <div class="contact-info-panel col-xs-12 col-md-3 col-lg-2">
        <div class="top-info">
          <div class="text-uppercase">Husguiden</div>
          <div>BYGGFAKTA Sverige i Ljusdal AB</div>
          <div>Östernäsvägen 1</div>
          <div>Tel 0651-55 25 00</div>
          <div>
            <a href="mailto:info@husguiden.nu">info@husguiden.nu</a>
          </div>
          <div>
            <a href="www.husguiden.nu">www.husguiden.nu</a>
          </div>
        </div>

        <div class="bottom-info">
          <div class="bottom-info-one">
            <p>Vill du medverka i foldern?</p>
            <p>Vill du nå ut till fler kommuner?</p>
            <p>
              <a href="mailto:medverka@husguiden.nu">
                <strong>medverka@husguiden.nu</strong>
              </a>
            </p>
          </div>
          <div class="bottom-info-two">
            <p>Vill du skicka in färdigt säljbrev?</p>
            <p>Vill du gör ändringar i layouten?</p>
            <p>
              <a href="mailto:material@husguiden.nu">
                <strong>material@husguiden.nu</strong>
              </a>
            </p>
          </div>
          <div class="bottom-info-jobs">
            <a
              href="https://karriar.byggfakta.se/"
              target="_blank"
            >
              <button
                class="btn btn-husguiden"
              >
                Jobba hos oss
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="employee-panel col-xs-12 col-md-9 col-lg-9">
        <div class="row">
          <div
            class="col-xs-12 col-md-3 col-lg-3 employee-column"
            v-for="employee in employees"
            :key="`${employee.name}${employee.title}`"
          >
            <div class="employee-info">
              <div class="user-icon">
                <img
                  :src="require(`../../../assets/images/contact/${employee.image}`)"
                  class="img-responsive"
                  :style="`${employee.css}`"
                />
                <!--<span class="glyphicon glyphicon-user"></span>-->
              </div>
              <div class="employee-text">
                <h3>{{ employee.title }}</h3>
                <p>{{ employee.name }}</p>
                <p>{{ employee.phone }}</p>
                <p>
                  <a :href="`mailto:${employee.email}`">{{ employee.email }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="contact-bottom-panel" style="width:100%;">
            <div
              class="logo-text">Vi är den ledande leverantören av information till den nordiska byggbranchen och tjänar även fastighets-, vård- och anbudssektorn.</div>
            <div class="logo-container">
              <img style="width: 120px" :src="require(`../../../assets/images/hubexo.svg`)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employees: [
        {
          title: 'Säljchef',
          name: 'Fajko Hafizović',
          phone: '0651 - 55 26 03',
          email: 'fajko.hafizovic@husguiden.nu',
          image: '2023-05-26/fajkohafizovic_bw600x850.jpg',
        },
        {
          title: 'Säljare',
          name: 'Carl-Oskar Öhlén',
          phone: '0651 - 55 25 95',
          email: 'carl-oscar.ohlen@husguiden.nu',
          image: '2023-05-26/carloskar_bw.jpg',
        },
        {
          title: 'Säljare',
          name: 'Christian Brolin',
          phone: '0651 - 55 25 10',
          email: 'christian.brolin@husguiden.nu',
          image: '2023-05-26/FotograferingSVARTVIT.jpg',
        },
        {
          title: 'Säljare',
          name: 'Marcus Östberg',
          phone: '0651 - 55 25 13',
          email: 'marcus.ostberg@husguiden.nu',
          image: '2023-05-26/marcusostberg_bw.jpg',
        },
        {
          title: 'Säljare',
          name: 'Simon Nilsson',
          phone: '0651 - 55 25 24',
          email: 'simon.nilsson@husguiden.nu',
          image: '2023-05-26/simonnilsson_bw.jpg',
        },
        {
          title: 'Graphic Designer',
          name: 'Kenneth Andersson',
          phone: '0651 - 55 25 21',
          email: 'material@husguiden.nu',
          image: 'color/kenneth.jpg',
        },
        {
          title: 'Affärsutvecklare',
          name: 'Gert Wängman',
          phone: '0651-55 25 55',
          email: 'gert.wangman@husguiden.nu',
          image: '2024-05-08/gert.jpg',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 0px) {
  .footer {
    bottom: -150px;
  }
}

@media (min-width: 448px) {
  .footer {
    bottom: -100px;
  }
}

/** Contact **/

@media (min-width: 0px) {
  .contact-info-panel {
    display: block;
    background: #eeedec;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .contact-info-panel .top-info {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .contact-info-panel .bottom-info p {
    margin: 0;
  }

  .contact-info-panel .bottom-info .bottom-info-one {
    margin-bottom: 10px;
  }

  .employee-panel {
    display: block;
    margin-top: 15px;
  }

  .bottom-info-jobs {
    margin-top: 16px;
  }

  .employee-info {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 12px;

    .user-icon {
      font-size: 38px;
      text-align: center;
      margin-bottom: 5px;
      border-bottom: 1px solid #ddd;

      img {
        display: block;
        margin: 0 auto;
        filter: grayscale(1);
      }

      .glyphicon {
        text-align: center;
        width: 160px;
        display: block;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        height: 50px;
        font-size: 50px;
      }
    }
  }

  .employee-info p {
    margin: 0 0 0 0;
    text-align: center;
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }

  .employee-info h3 {
    text-align: center;
    margin-top: 0;
  }

  .contact-bottom-panel {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    position: relative;
    float: left;
    width: 100%;
    min-height: 80px;
    background: #eeedec;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    font-style: italic;
    margin-top: 20px;
    text-align: center;
    flex-direction: column;
    padding: 16px;
  }

  .contact-bottom-panel .logo-text {
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
  }

  .contact-bottom-panel .logo-container {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .employee-info {
    min-height: 180px;
  }
}

@media (min-width: 992px) {
  .contact-info-panel {
    display: inline-block;
    background: #eeedec;
    //min-height: 492px;
    min-height: 720px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-radius: 3px;
    border-top-right-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    padding-left: 15px;
    padding-right: 25px;
    margin-right: 0;
    font-size: 14px;
    width: 20%;
  }

  .contact-info-panel .col-md-3 {
    width: 20%;
  }

  .employee-panel {
    display: inline-block;
    padding: 0;
    margin: 0px 0 0 20px;
    font-size: 12px;
  }

  .employee-column {
    padding-right: 0;
  }

  .employee-info {
    min-height: 295px;
    border: none;
    border: 1px solid #ddd !important;
    border-radius: 2px !important;
  }

  .employee-info .user-icon {
    font-size: 38px;
    text-align: left;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  }

  .employee-info .user-icon img {
    display: block;
    margin: 0 auto;
  }

  .employee-info p {
    text-align: center;
  }

  .employee-info h3 {
    text-align: center;
  }

  .contact-bottom-panel {
    float: right;
    position: relative;
    width: 79.8%;
    min-height: 80px;
    background: #eeedec;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-top: 0;
    font-size: 14px;
    font-style: italic;
    flex-direction: row;
  }

  .contact-bottom-panel .logo-text {
    position: relative;
    display: inline-block;
    width: 48%;
    padding-right: 5px;
    padding-left: 15px;
    border-right: 1px solid #000;
    margin-left: 20%;
  }

  .contact-bottom-panel .logo-container {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .contact-info-panel {
    padding-left: 40px;
    padding-right: 25px;
    min-height: 680px;
  }

  .employee-info {
    min-height: 275px;
    border: none;
  }

  .contact-bottom-panel {
    min-height: 80px;
    margin-bottom: 20px;
    width: 79.8%;
  }

  .contact-bottom-panel .logo-container {
    display: inline-block;
  }
}

.employee-info img {
  height: 200px;
}

</style>
